// Primary language tags for those languages we support
export const AVAILABLE_LOCALES = {
  en: "English",
  zh: "简体中文",//TODO：XMUSEUM修改
  // TODO：XMUSEUM, 取消除中英文以外的语言
  // pt: "Portugês (Brasil)",
  // ja: "日本語",
  // es: "Español",
  // ru: "Pусский",
  // fr: "Français"
};

// Map from alternative primary language tags to those we support
export const FALLBACK_LOCALES = {//TODO：XMUSEUM修改
  // jp: "ja"
  "zh-cn": "zh",
  "zh-hans": "zh",
  "zh-hans-cn": "zh"
};
